/**=====================
     34. Pricing CSS Start
==========================**/
.pricing-simple {
  box-shadow: 0 0 10px 5px rgba(68, 102, 242, 0.05);
  margin-bottom: 0 !important;
  .card-body {
    border-bottom: none;
    h3 {
      position: relative;
      margin-bottom: 38px;

      &:before {
        position: absolute;
        content: '';
        width: 80px;
        height: 1px;
        margin: 0 auto;
        left: 0;
        right: 0;
        bottom: -10px;
        background-color: $light-text;
      }
    }

    h1 {
      font-size: 30px;
      line-height: 0.9;
      margin-bottom: 30px;
      font-weight: 400;
    }
  }
}

.pricingtable {
  text-align: center;
  padding-top:45px;
  padding-bottom:45px;
  // border-radius: 10px;
  border-radius: 10px 10px 10px 10px; /* Apply border-radius to all corners except bottom-right */
  // border-end-end-radius: 10px; /* Apply border-radius to all corners */
  box-shadow: $card-hover-box-shadow;
  overflow: hidden;
  position: relative;
  z-index: 1;
  height:100%;
  &:before,
  &:after {
    content: "";
   // background-image: url('../../../assets/images/plans.png'); 
    // background-color: var(--theme-deafult);
    // width: 300px;
    // height: 300px;
    // border-radius: 50%;
    // //position: absolute;
    // right: -213px;
    // z-index: -1;
    // opacity: 0.1;
  }
 
}



.pricingtable {
  &:after {
    // background-color: var(--theme-deafult);
    // height: 300px;
    // width: 300px;
    // top: auto;
    bottom: -210px;
    right: auto;
    left: -160px;
    opacity: 0.1;
  }

  .title {
    text-transform:uppercase;
    margin-bottom:20px;
    font-size: 21px;
  color: white;
  }

  .price-value {
    color: $white;
    background-color: var(--theme-deafult);
    width: 130px;
    height: 130px;
    padding: 45px 0 0;
    margin: 0 auto 25px;
    border-radius: 50%;
    .duration {
      margin: 0 0 0 -5px;
    }
    .amount {
      font-size: 45px;
      font-weight: 700;
      line-height: 45px;
      display: inline-block;
    }

    .currency {
      vertical-align: top;
      font-size: 20px;
    }
  }

  .pricing-content {
    margin: 0 0 25px;
    display: inline-block;
    li {
      font-weight: 500;
      text-align: left;
      margin: 0 0 20px;
      position: relative;
      padding-left: 30px;
      color: white;

      &:last-child {
        margin: 0;
      }

      &:before {
        content: "\f00c";
        color: var(--theme-deafult);
        font-family: FontAwesome;
        text-align: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        top: 1px;
        left: 0;
      }
    }
  }
}

@media (max-width:1470px){
  .pricing-block{
    >div{
      flex:50%;
      &:nth-child(n+3){
        margin-top:30px;
      }
    }
  }

}

@media only screen and (max-width: 991px) { 
  .pricing-simple {
    .card-body {
      h1 {
        margin-bottom: 20px;
      }
      h3 {
        margin-bottom: 28px;
      }
    }
  }

  .pricing-block{
    >div{      
      &:nth-child(n+2){
        margin-top:20px;
      }
    }
  }
 
 

}

@media only screen and (max-width: 575px) {
  .pricing-simple {
    .card-body {
      h1 {
        margin-bottom: 15px;
      }
    }
  }
  .pricing-block {
    > div{
      flex:100%;
    }
  }

  .pricingtable{
    padding-top:30px;
    padding-bottom:30px;
  }

  // .pricingtable-signup{
  //   font-size: 18px;
  //   color: black;
  //   background-color: white;
  // }
}
.pricingtable .price-value {
  color:rgba(126, 163, 207, 1) ;
  background-color: white;

  width: 130px;
  height: 130px;
  padding: 45px 0 0;
  margin: 0 auto 25px;
  border-radius: 50%;
}

.pricingtable .pricing-content li:before {
  content: "\f00c";
    color: #f4f4f4;
    font-family: FontAwesome;
    text-align: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 0;
}







@media (max-width:1470px) {
  .pricing-block > div {
    flex: 50%;
    &:nth-child(n+3) {
      margin-top: 30px;
    }
  }
}



/**=====================
    34. Pricing CSS Ends
==========================**/

















/**=====================
     34. Pricing CSS Start
==========================**/
// .pricing-simple {
//   box-shadow: 0 0 10px 5px rgba(68, 102, 242, 0.05);
//   margin-bottom: 0 !important;
//   .card-body {
//     border-bottom: none;
//     h3 {
//       position: relative;
//       margin-bottom: 38px;

//       &:before {
//         position: absolute;
//         content: '';
//         width: 80px;
//         height: 1px;
//         margin: 0 auto;
//         left: 0;
//         right: 0;
//         bottom: -10px;
//         background-color: $light-text;
//       }
//     }

//     h1 {
//       font-size: 30px;
//       line-height: 0.9;
//       margin-bottom: 30px;
//       font-weight: 400;
//     }
//   }
// }

// .pricingtable {
//   text-align: center;
//   padding-top:45px;
//   padding-bottom:45px;
//   border-radius: 10px;
//   box-shadow: $card-hover-box-shadow;
//   overflow: hidden;
//   position: relative;
//   z-index: 1;
//   height:100%;
//   &:before,
//   &:after {
//     content: "";
//     background-color: var(--theme-deafult);
//     width: 300px;
//     height: 300px;
//     border-radius: 50%;
//     position: absolute;
//     right: -213px;
//     z-index: -1;
//     opacity: 0.1;
//   }
 
// }



// .pricingtable {
//   &:after {
//     background-color: var(--theme-deafult);
//     height: 300px;
//     width: 300px;
//     top: auto;
//     bottom: -210px;
//     right: auto;
//     left: -160px;
//     opacity: 0.1;
//   }

//   .title {
//     text-transform:uppercase;
//     margin-bottom:20px;
//   }

//   .price-value {
//     color: $white;
//     background-color: var(--theme-deafult);
//     width: 130px;
//     height: 130px;
//     padding: 45px 0 0;
//     margin: 0 auto 25px;
//     border-radius: 50%;
//     .duration {
//       margin: 0 0 0 -5px;
//     }
//     .amount {
//       font-size: 58px;
//       font-weight: 700;
//       line-height: 45px;
//       display: inline-block;
//     }

//     .currency {
//       vertical-align: top;
//     }
//   }

//   .pricing-content {
//     margin: 0 0 25px;
//     display: inline-block;
//     li {
//       font-weight: 500;
//       text-align: left;
//       margin: 0 0 20px;
//       position: relative;
//       padding-left: 30px;

//       &:last-child {
//         margin: 0;
//       }

//       &:before {
//         content: "\f00c";
//         color: var(--theme-deafult);
//         font-family: FontAwesome;
//         text-align: center;
//         width: 20px;
//         height: 20px;
//         border-radius: 50%;
//         position: absolute;
//         top: 1px;
//         left: 0;
//       }
//     }
//   }
// }

// @media (max-width:1470px){
//   .pricing-block{
//     >div{
//       flex:50%;
//       &:nth-child(n+3){
//         margin-top:30px;
//       }
//     }
//   }

// }

// @media only screen and (max-width: 991px) { 
//   .pricing-simple {
//     .card-body {
//       h1 {
//         margin-bottom: 20px;
//       }
//       h3 {
//         margin-bottom: 28px;
//       }
//     }
//   }

//   .pricing-block{
//     >div{      
//       &:nth-child(n+2){
//         margin-top:20px;
//       }
//     }
//   }
 
 

// }

// @media only screen and (max-width: 575px) {
//   .pricing-simple {
//     .card-body {
//       h1 {
//         margin-bottom: 15px;
//       }
//     }
//   }
//   .pricing-block {
//     > div{
//       flex:100%;
//     }
//   }

//   .pricingtable{
//     padding-top:30px;
//     padding-bottom:30px;
//   }


// }

// /**=====================
//     34. Pricing CSS Ends
// ==========================**/








